<template>
    <div id="pageList" class="QuotationList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">报价单名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入报价单名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">报价单类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择报价单类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">城市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" clearable placeholder="请选择城市" style="width: 100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
<!--                    <el-col :span="3" class="myColTitle">添加人：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                        <el-select :ref="el=>refMap.set('userId',el)" v-model="pageList.queryParam.userId" placeholder="请选择添加人" clearable style="width:100%">-->
<!--                            <el-option v-for="item in comboSelect({comboId:'userId'})" :key="item.value" :label="item.label" :value="item.value"/>-->
<!--                        </el-select>-->
<!--                    </el-col> -->
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('forbiddenFlag',el)" v-model="pageList.queryParam.status" placeholder="请选择启用/禁用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'forbiddenFlag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
<!--                    <el-col :span="3" class="myColTitle">创建时间：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                        <el-date-picker v-model="pageList.queryParam.time" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>-->
<!--                    </el-col>-->
                </el-row>
            </template>

            <template v-slot:tbCols>
                <el-table-column prop="F_CODE" label="报价单编号">
                    <template #default="scope">
                        <el-tag style="cursor: pointer" @click="viewQuotationPriceConfig(scope.row)">{{ scope.row.F_CODE }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="F_NAME" label="报价单名称"/>
                <el-table-column prop="F_FLAG" label="按结算百分比"/>
                <el-table-column prop="F_CITY_ID" label="城市"/>
                <el-table-column prop="F_FORBIDDEN_FLAG" label="是否停用"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
<!--                <el-table-column prop="F_USER_ID" label="添加人"/>-->
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import QuotationCard from '@/views/project/lghd/xsgl/customerQuotation/QuotationCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "DriverQuotationList",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                cityData:[],
                pageList: {
                    isShowFixCol:props.engineParams?false:true,
                    queryParam: {
                        moduleType:'driver',
                        name:"",
                        cityId:"",
                        flag:"",
                        status:"",
                        forbiddenFlag:"",
                       // userId:"",
                        time:"",
                        from:props.engineParams?props.engineParams.from:'',//运力配置选择承运商报价单，弹出框传过来的参数from=’ylpz‘
                        saleBillId:props.engineParams?props.engineParams.saleBillId:''//运力配置选择承运商报价单，弹出框传过来的参数saleBillId，其值等于要配置承运商报价单的销售单ID
                    },
                    modelComp: QuotationCard,
                    modelMethod: "/driverQuotation/pageData"
               }
            })
            onMounted(async ()=>{
                await buildCityData();
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待审核'},{value:1,label:'通过'},{value:2,label:'拒绝'}]
                    }
                    if(params && 'forbiddenFlag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'标准产品'},{value:1,label:'非标产品'}]
                    }
                }
            })
            const buildCityData=async ()=>{
                dataObj.cityData = await utils.$$api.getCitiesByChannelId();
            }

            const viewQuotationPriceConfig=async (row)=>{
                let id=row['F_ID'];
                let flag=row['F_FLAG'];
                if(flag=='否'){//如果不是按比例，那么就显示报价单的费用配置，否则就直接显示报价单
                    utils.$$lghdUtils.buildQuotationPriceConfigDialog({id:id,proxy:proxy,title:'承运商报价单'+row['F_CODE']+'费用配置'});
                }else{
                    dataObj.pageListRef.editHandler(row);
                }
            }
            return{
                ...toRefs(dataObj),comboSelect,viewQuotationPriceConfig
            }
        }
    });
</script>

<style scoped>
    .QuotationList{
        width: 100%;
    }
</style>